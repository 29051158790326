import { createApp } from "@shopify/app-bridge";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { useCallback, useMemo } from "react";

/**
 * Todo: Replace every dependant component's of this hooks with new Shopify App Bridge 4.0 [Navigation API](https://shopify.dev/docs/api/app-bridge-library/apis/navigation)
 */

export function useAppBridgeRedirect() {
  const shopify = useAppBridge();
  const app = createApp(shopify.config);
  const redirect = useMemo(() => Redirect.create(app), [app]);

  const REMOTE = useCallback(
    (url) => {
      redirect.dispatch(Redirect.Action.REMOTE, url);
    },
    [redirect]
  );

  const ADMIN_PATH = useCallback(
    (path, { external = false } = {}) => {
      redirect.dispatch(Redirect.Action.ADMIN_PATH, { path, newContext: external });
    },
    [redirect]
  );

  const APP = useCallback(
    (path) => {
      redirect.dispatch(Redirect.Action.APP, path);
    },
    [redirect]
  );

  const RELOAD = useCallback(() => {
    redirect.dispatch(Redirect.Action.RELOAD);
  }, [redirect]);

  const handleAddProducts = () => ADMIN_PATH("/products");
  const handleAddPages = () => ADMIN_PATH("/pages");
  const handleAddArticles = () => ADMIN_PATH("/articles");
  const handleAddCollections = (options = {}) => ADMIN_PATH("/collections", { ...options });

  const handleEditProduct = (id) => ADMIN_PATH("/products/:id".replace(":id", id));
  const handleEditPage = (id, options = {}) => ADMIN_PATH("/pages/:id".replace(":id", id), { ...options });
  const handleEditArticle = (id, options = {}) => ADMIN_PATH("/articles/:id".replace(":id", id), { ...options });

  const handleEditCollection = (id, options = {}) => ADMIN_PATH("/collections/:id".replace(":id", id), { ...options });

  return {
    APP,
    REMOTE,
    ADMIN_PATH,
    RELOAD,
    handleAddProducts,
    handleAddPages,
    handleAddArticles,
    handleEditProduct,
    handleEditPage,
    handleEditArticle,
    handleAddCollections,
    handleEditCollection,
  };
}
